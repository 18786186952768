import {connect} from "react-redux";

const PageNotFound = ({}) => {

  return (
    <>      
      <h1>404 Page not found</h1>
      <div>
        <a href="/dashboard">
          Take me home!
        </a>
      </div>
    </>
  );
};

const mapDispatch = {  
}

export default connect(null, mapDispatch)(PageNotFound);
