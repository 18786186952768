import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { logout } from "../actions/user.actions";
import { LogoutIcon } from './Icon/IconAssets';
import { LayoutIcon } from './Icon/IconAssets/LayoutIcon';
import { DashboardIcon } from './Icon/IconAssets/DashboardIcon';
import { RadarIcon } from './Icon/IconAssets/RadarIcon';
import { CreativesIcon } from './Icon/IconAssets/CreativesIcon';
import { LightBulbIcon } from './Icon/IconAssets/LightBulbIcon';
import { CollectionIcon } from './Icon/IconAssets/CollectionIcon';
import { useLocation } from 'react-router-dom';

const Sidebar = ({ user, logout }) => {    
  const location = useLocation();

  const handleLogout = () => {
    logout();
  } 

  return (
    <div className="w-60 bg-[#170E08] min-h-screen flex flex-col rounded-r-[20px]">
      <div className="flex-shrink-0 p-4 text-[#D1CFCE] border-b-2 border-[#2D3139] pt-10 mb-8 text-center">
        <h2 className="text-3xl font-bold">futurads</h2>
      </div>
      <ul className="flex-grow p-2 space-y-4">
        <li>
        <Link 
          to="/dashboard" 
          className={`group flex items-center space-x-2 text-white ${location.pathname === "/dashboard" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md p-2`}
        >
          <div 
            className={`rounded-full p-2 flex items-center justify-center ${location.pathname === "/dashboard" ? 'bg-[#170E08]' : 'bg-[#392314] group-hover:bg-[#170E08]'}`}
          >
            <DashboardIcon />
          </div>
          <span>Dashboard</span>
        </Link>
        </li>
        <li>
          <Link 
            to="/briefs" 
            className={`group flex items-center space-x-2 text-white ${location.pathname === "/briefs" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md p-2`} >
            <div 
              className={`rounded-full p-2 flex items-center justify-center ${location.pathname === "/briefs" ? 'bg-[#170E08]' : 'bg-[#392314] group-hover:bg-[#170E08]'}`} >
              <RadarIcon stroke="#FF600D" />
            </div>
            <span>Briefs</span>
          </Link>
        </li>
        <li>
          <Link 
            to="/insights" 
            className={`group flex items-center space-x-2 text-white ${location.pathname === "/insights" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md p-2`} >
            <div 
              className={`rounded-full p-2 flex items-center justify-center ${location.pathname === "/insights" ? 'bg-[#170E08]' : 'bg-[#392314] group-hover:bg-[#170E08]'}`} >
            <LightBulbIcon stroke="#FF600D" />
            </div>
            <span>Insights</span>
          </Link>
        </li>
        <li>
          <Link 
            to="/formats" 
            className={`group flex items-center space-x-2 text-white ${location.pathname === "/formats" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md p-2`} >
            <div 
               className={`rounded-full p-2 flex items-center justify-center ${location.pathname === "/formats" ? 'bg-[#170E08]' : 'bg-[#392314] group-hover:bg-[#170E08]'}`} >
              <LayoutIcon stroke="#FF600D" />
            </div>
            <span>Formats</span>
          </Link>
        </li>
        <li>
          <Link 
            to="/creatives" 
            className={`group flex items-center space-x-2 text-white ${location.pathname === "/creatives" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md p-2`} >
            <div 
              className={`rounded-full p-2 flex items-center justify-center ${location.pathname === "/creatives" ? 'bg-[#170E08]' : 'bg-[#392314] group-hover:bg-[#170E08]'}`} >
              <CreativesIcon stroke="#FF600D" />
            </div>
            <span>Creatives</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/dubbs" className="group flex items-center space-x-2 text-white hover:bg-[#392314] rounded-md p-2">
            <div className="bg-[#392314] group-hover:bg-[#170E08] rounded-full p-2 flex items-center justify-center">
              <LanguageIcon />
            </div>
            <span>Localization</span>
          </Link>
        </li> */}
        <li>
        <Link 
          to="/assets" 
          className={`group flex items-center space-x-2 text-white ${location.pathname === "/assets" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md p-2`} >
          <div 
            className={`rounded-full p-2 flex items-center justify-center ${location.pathname === "/assets" ? 'bg-[#170E08]' : 'bg-[#392314] group-hover:bg-[#170E08]'}`} >
              <CollectionIcon stroke="#FF600D" />
            </div>
            <span>Asset Library</span>
          </Link>
        </li>
      </ul>
      <div className="flex-shrink-0 space-y-4">
        <div className="flex flex-col items-start">        
          <Link to="/account" className={`group block space-x-2 text-[#FF6200] ${location.pathname === "/account" ? 'bg-[#392314]' : ''} hover:bg-[#392314] rounded-md px-2 py-2 w-[96%] mx-1`}>

          

            <div className="flex flex-col items-start space-y-2">
              <span className="text-md text-white pl-4">{user.first_name}&nbsp;{user.last_name}</span>    
              <div className="flex items-center">
                <span className='text-xs pl-4'>My Account</span>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex-shrink-0">
          <div className="pt-5 pb-4 border-t-2 border-[#2D3139] pl-7">
            <button onClick={handleLogout} className="flex items-center space-x-2 text-white">
              <LogoutIcon stroke="white" />
              <span className='pl-2 text-sm'>Log Out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = (state) => ({
  user: state.user.user
});

const mapDispatch = {
  logout,  
};

export default connect(mapState, mapDispatch)(Sidebar);
