export const DataIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.482 9.26v6.694M11.037 6.058v9.898M15.516 12.798v3.157"
    />
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.3 11.037C1.3 3.735 3.735 1.3 11.037 1.3s9.736 2.435 9.736 9.737-2.434 9.736-9.736 9.736c-7.302 0-9.737-2.434-9.737-9.736Z"
      clipRule="evenodd"
    />
  </svg>
);
