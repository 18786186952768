export const LogoutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="16" fill="none" viewBox="0 0 17.6 16" {...props}>
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      d="M16.6328 8.096 H7.0 M14.2912 5.764 l2.3424 2.3328 -2.3424 2.3328 M12.288 4.504 c-0.264 -2.864 -1.336 -3.904 -5.6 -3.904 C1.0072 0.6 1.0072 2.448 1.0072 8.0 c0.0 5.552 0.0 7.4 5.68 7.4 4.264 0.0 5.336 -1.04 5.6 -3.904 "
    />
  </svg>
);
