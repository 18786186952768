import { DataIcon } from "./Icon/IconAssets/DataIcon";

export function AnalyticsInfo({ name, value }) {
  return (
    <div className="flex w-full justify-center ">
      <div
        className=
          'border-border flex w-full max-w-[380px] items-center gap-8 rounded-xl border px-8 py-6'>
        <div className="bg-[#FFF5F0] w-14 rounded-full p-4">
          <DataIcon stroke={'#FF600D'} />
        </div>
        <div>
          <h3 className="text-[14px] text-[#707B8F]">{name}</h3>
          <h2 className="text-[#2D3139] text-[36px] font-semibold">{value}</h2>
        </div>
      </div>
    </div>
  );
}
