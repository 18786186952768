import api from './api'

export const uploadVideoRequest = (formData) => {
  return api.post('/projects/video/upload/', formData);
};

export const getVideoListRequest = (pageSize) => {
  return api.get(`/projects/video/list/?size=${pageSize}`);
};

export const deleteVideoRequest = (videoId) => {
  return api.delete(`/projects/video/delete/${videoId}/`);
};