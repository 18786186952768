import api from './api'

export const loginUser = (email, password) => {
  return api.post('/accounts/signin/', {
    email: email,
    password,
  });
};

export const getCurrentUser = () => {
  return api.get('/accounts/profile/');
};

export const createUser = (data) => {  
  return api.post('/accounts/signup/', data);
};

export const updateUserDetails = (data) => {  
  return api.patch(`/accounts/profile/`, data);
};

export const deleteUser = () => {  
  return api.delete(`/accounts/delete-account/`);
};

export const putPassword = (data) => {  
  return api.put(`/accounts/change-password/`, data);
};