import LoginImg from '../assets/login.png';


const BackgroundImage = () => {
  return (
    <div className="w-[90%] h-[98%] bg-cover bg-center bg-black rounded-[60px] ml-2 my-2" style={{ backgroundImage: `url(${LoginImg})` }}>
    </div>
  );
}

export default BackgroundImage;
