import { useState } from 'react'
import { connect } from "react-redux";
import { updatePassword } from '../actions/user.actions';

const ChangePassword = ({ updatePassword }) => {  
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: ""
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      updatePassword(formData)
  }

  return (
    <div className="pt-10">
      <div className="w-[40%]">            
          <form onSubmit={handleSubmit}>
            <div className="relative mt-4">
              <label className="block text-sm font-medium mb-2">Old Password</label>
              <input 
                  type="password" 
                  name="old_password" 
                  value={formData.old_password}
                  onChange={handleChange}
                  className="border rounded-lg p-2 w-full"
                  required
              />                
            </div>
            <div className="relative mt-4">
              <label className="block text-sm font-medium mb-2">New Password</label>
              <input 
                  type="password" 
                  name="new_password"
                  value={formData.new_password}
                  onChange={handleChange}
                  className="border rounded-lg p-2 w-full"
                  required
              />
            </div>
            <div className="relative mt-4">
              <label className="block text-sm font-medium mb-2">Confirm New Password</label>                    
              <input 
                  type="password" 
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                  className="border rounded-lg p-2 w-full"
                  required
              />
            </div>
            <button 
              type="submit"
              className="bg-[#FF600D] text-white rounded-full p-2 w-full mt-10 hover:bg-opacity-90"
            >
              Change Password
            </button>
          </form>
          {message && <p>{message}</p>}
        </div>
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
});

const mapDispatch = {  
  updatePassword,
};

export default connect(mapState, mapDispatch)(ChangePassword);
