import { connect } from "react-redux";
import { deleteVideo } from '../actions/videos.actions';

const VideoList = ({ video, deleteVideo }) => {    

    const handleDelete = (videoId) => {
        deleteVideo(videoId);
    }    

    return (        
        <div key={video.id} className="relative">
            <img 
                className="w-full h-48 object-cover rounded-md border-2 border-[#d3d3d3]" 
                src={video.thumbnail || `https://placehold.co/150x150?text=${video.name}`} 
                alt={`Thumbnail for video ${video.id}`} 
            />
            <div className="flex items-center justify-between mt-2">
                <span className="truncate">{video.name}</span>
                <button 
                    className="text-red-500 hover:underline"
                    onClick={() => handleDelete(video.id)}
                >
                    remove
                </button>
            </div>
        </div>          
    );
}               

const mapState = (state) => ({  
});

const mapDispatch = {    
  deleteVideo
};

export default connect(mapState, mapDispatch)(VideoList);
