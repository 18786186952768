//import "./App.css";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import AppLayout from "./layouts/AppLayout";
import Dubbs from "./pages/Dubbs";
import AssetLibrary from "./pages/AssetLibrary";
import PageNotFound from "./pages/PageNotFound";
import PublicLayout from "./layouts/PublicLayout";
import AccountInfo from "./pages/AccountInfo";
import ChangePassword from "./pages/ChangePassword";
import Billing from "./pages/Billing";
import StripeSuccess from "./pages/StripeSuccess";
import StripeCancelled from "./pages/StripeCancelled";
import Insights from "./pages/Insights";
import Creatives from "./pages/Creatives";
import Briefs from "./pages/Briefs";
import Formats from "./pages/Formats";
import { Route, Routes, Navigate, BrowserRouter, } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route path="/" element={<Navigate replace to="signIn" />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/signUp" element={<SignUp />} />          
        </Route>

        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />      
          <Route path="/dubbs" element={<Dubbs />} />      
          <Route path="/assets" element={<AssetLibrary />} />          
          <Route path="/insights" element={<Insights />} />          
          <Route path="/creatives" element={<Creatives />} />          
          <Route path="/briefs" element={<Briefs />} />          
          <Route path="/formats" element={<Formats />} />          
          <Route path="/account" element={<Account />}>             
            <Route index element={<AccountInfo />} />
            <Route path="password" element={<ChangePassword />} />
            <Route path="billing" element={<Billing />} />
            <Route path="success" element={<StripeSuccess />} />
            <Route path="cancelled" element={<StripeCancelled />} />
          </Route>          
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;