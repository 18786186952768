import { 
    UPLOAD_VIDEO_BEGIN, 
    UPLOAD_VIDEO_ERROR, 
    UPLOAD_VIDEO_SUCCESS,
    SAVE_UPLOADED_VIDEO,
    FETCH_VIDEOS_BEGIN,
    FETCH_VIDEOS_SUCCESS,
    FETCH_VIDEOS_ERROR,
    DELETE_VIDEO_BEGIN,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_ERROR
} from './types';
import { 
    uploadVideoRequest,
    getVideoListRequest,
    deleteVideoRequest 
} from '../api/videos';

export const uploadVideo = (file) => async dispatch => {
    dispatch({ type: UPLOAD_VIDEO_BEGIN });    
    const formData = new FormData();
    formData.append('video_file', file);
    try {
        const response = await uploadVideoRequest(formData);
        dispatch({ type: UPLOAD_VIDEO_SUCCESS, payload: response.data });
        // Dispatching a new action to save the video data
        dispatch({ type: SAVE_UPLOADED_VIDEO, payload: response.data });
    } catch (error) {
        dispatch({ type: UPLOAD_VIDEO_ERROR, payload: error.message });
    }
};

export const fetchVideos = (pageSize) => async dispatch => {
    dispatch({ type: FETCH_VIDEOS_BEGIN });
    try {
        const response = await getVideoListRequest(pageSize);
        dispatch({ type: FETCH_VIDEOS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_VIDEOS_ERROR, payload: error.message });
    }
};

export const deleteVideo = (videoId) => async dispatch => {
    dispatch({ type: DELETE_VIDEO_BEGIN });
    try {
        await deleteVideoRequest(videoId);
        dispatch({ type: DELETE_VIDEO_SUCCESS, payload: videoId });
    } catch (error) {
        dispatch({ type: DELETE_VIDEO_ERROR, payload: error.message });
    }
};

// export const dubVideo = (videoId, language) => async dispatch => {
//     // Dispatch an action to indicate the dubbing process has begun
//     dispatch({ type: DUB_VIDEO_BEGIN });
    
//     try {
//         // Make an API request to dub the video
//         const response = await api.post('/path_to_dubbing_endpoint/', { videoId, language });
        
//         // Dispatch a success action if everything went well
//         dispatch({ type: DUB_VIDEO_SUCCESS, payload: response.data });
//     } catch (error) {
//         // Dispatch an error action if something went wrong
//         dispatch({ type: DUB_VIDEO_ERROR, payload: error.message });
//     }
// };