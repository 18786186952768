export const SIGNIN_BEGIN             = "SIGNIN_BEGIN";
export const SIGNIN_ERROR             = "SIGNIN_ERROR";
export const SIGNUP_BEGIN             = "SIGNUP_BEGIN";
export const SIGNUP_ERROR             = "SIGNUP_ERROR";
export const SIGNIN_SUCCESS           = "SIGNIN_SUCCESS";
export const SIGNUP_SUCCESS           = "SIGNUP_SUCCESS";
export const LOGOUT_SUCCESS           = "LOGOUT_SUCCESS";
export const DELETE_ACCOUNT_BEGIN     = "DELETE_ACCOUNT_BEGIN";
export const DELETE_ACCOUNT_SUCCESS   = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR     = "DELETE_ACCOUNT_ERROR";
export const UPDATE_ACCOUNT_BEGIN     = "UPDATE_ACCOUNT_BEGIN";
export const UPDATE_ACCOUNT_SUCCESS   = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_ERROR     = "UPDATE_ACCOUNT_ERROR";
export const CHANGE_PASSWORD_BEGIN    = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS  = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR    = 'CHANGE_PASSWORD_FAILURE';
export const CHECKOUT_SESSION_BEGIN   = 'CHECKOUT_SESSION_BEGIN';
export const CHECKOUT_SESSION_SUCCESS = 'CHECKOUT_SESSION_SUCCESS';
export const CHECKOUT_SESSION_ERROR   = 'CHECKOUT_SESSION_ERROR';
export const UPLOAD_VIDEO_BEGIN       = 'UPLOAD_VIDEO_BEGIN';
export const UPLOAD_VIDEO_SUCCESS     = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_ERROR       = 'UPLOAD_VIDEO_ERROR';
export const SAVE_UPLOADED_VIDEO      = 'SAVE_UPLOADED_VIDEO';
export const FETCH_VIDEOS_BEGIN       = 'FETCH_VIDEOS_BEGIN';
export const FETCH_VIDEOS_SUCCESS     = 'FETCH_VIDEOS_SUCCESS';
export const FETCH_VIDEOS_ERROR       = 'FETCH_VIDEOS_ERROR';
export const DELETE_VIDEO_BEGIN       = 'DELETE_VIDEO_BEGIN';
export const DELETE_VIDEO_SUCCESS     = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_ERROR       = 'DELETE_VIDEO_ERROR';