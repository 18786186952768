import { produce } from 'immer';

import {
  SIGNIN_BEGIN,
  SIGNIN_ERROR,
  SIGNUP_BEGIN,
  SIGNUP_ERROR,
  SIGNIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  DELETE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_BEGIN,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "../actions/types";

const initialState = {
  user: null,  
  email: null,
  loading: false,
  error: null,
  message: null,  
  isAuthenticated: false,
};

const user = produce((draft, action) => {
  switch (action.type) {
    case SIGNIN_BEGIN:
    case SIGNUP_BEGIN:
    case CHANGE_PASSWORD_BEGIN:
    case UPDATE_ACCOUNT_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.message = null;
      break;        
    case SIGNIN_SUCCESS:      
    case SIGNUP_SUCCESS:    
    case UPDATE_ACCOUNT_SUCCESS:
      draft.loading = false;            
      draft.user = action.payload;
      draft.isAuthenticated = true;
      break;    
    case CHANGE_PASSWORD_SUCCESS:
      draft.loading = false;                  
      draft.isAuthenticated = false;
      break;    
    case DELETE_ACCOUNT_SUCCESS:
      draft.loading = false;            
      draft.message = "Account deleted successfully";
      draft.isAuthenticated = false;
      break;
    case SIGNIN_ERROR:
    case SIGNUP_ERROR:        
      draft.loading = false;
      draft.error = action.error;
      draft.user = null;
      draft.isAuthenticated = false;
      break;    
    case UPDATE_ACCOUNT_ERROR:
    case CHANGE_PASSWORD_ERROR:
      draft.loading = false;
      draft.error = action.error;
      draft.user = null;      
      break;    
    case LOGOUT_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.user = null;
      draft.isAuthenticated = false;
      break;
    default:
      break;
  }
}, initialState);

export default user;
