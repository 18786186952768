import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initiateCheckout } from '../actions/checkout.actions';

const BuyTokensButton = () => {
  const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.checkout);

    const handlePurchase = () => {
        dispatch(initiateCheckout());
    };
    
    return <div>
      <button
        type="submit"
        onClick={handlePurchase}
        disabled={loading}
        className="bg-[#FF600D] text-white rounded-full p-2 w-[20%] mt-4 hover:bg-opacity-90"      
      >
        Buy Tokens
      </button>      
      {error && <p className='mt-6 ml-1 text-red-500'>Error: {error}</p>}
    </div>
};

export default BuyTokensButton;