import { useState } from "react";
import { connect } from "react-redux";
import EditableInput from "../components/EditableInput";
import { deleteAccount, updateAccountDetails } from "../actions/user.actions";

const AccountInfo = ({currentUser, deleteAccount, updateAccountDetails}) => {  
  const [userData, setUserData] = useState({
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    company: currentUser.company,
  }); 

  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    deleteAccount();
    setShowModal(false);
  };

  const handleSave = (field, value) => {    
    setUserData(prev => ({ ...prev, [field]: value }));
    updateAccountDetails({[field]: value});
  };

  return (
    <>
      <div className="grid grid-flow-col justify-stretch mt-20">      
        <div className="grid grid-flow-col justify-stretch space-x-6">
            <EditableInput 
                label="First Name" 
                initialValue={userData.first_name} 
                onSave={(value) => handleSave('first_name', value)} 
            />
            <EditableInput 
                label="Last Name" 
                initialValue={userData.last_name} 
                onSave={(value) => handleSave('last_name', value)} 
            />
        </div>
      </div>
      <div className="grid grid-flow-col justify-stretch">      
        <div className="grid grid-flow-col justify-stretch space-x-6 mt-4">
            <EditableInput 
                label="Company" 
                initialValue={userData.company} 
                onSave={(value) => handleSave('company', value)} 
            />
            <div className="relative mt-4">
                <label className="block text-sm font-medium mb-2">Email</label>
                <input 
                    type="text" 
                    value={currentUser.email}                  
                    className="border rounded-lg p-2 w-full"
                    readOnly
                    disabled
                />              
            </div>
        </div>            
      </div>    

      <div className="mt-20">
        <h2 className="text-2xl font-semibold mb-4 text-[#170E08]">Danger Zone</h2>
        <hr />
        <button 
            onClick={() => setShowModal(true)} 
            className="mt-8 outline outline-red-500 outline-1 text-red-500 rounded-full px-4 py-2"
        >
            Delete Account
        </button>
      </div>

      {/* Delete Account Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-8">
            <h3 className="font-bold mb-4">Considering Leaving Us?</h3>
            <p className="font-light mb-4">We truly value you being a part of our community. <br /> Before you go, it's important to know:</p>
            <p className="font-light">- <strong>Tokens:</strong>&nbsp;Any remaining tokens in your account will be forfeited.</p>
            <p className="font-light">- <strong>Data Removal:</strong>&nbsp;All application data linked to your account will be <br/>&nbsp;&nbsp;permanently deleted.</p>
            <p className="font-light">- <strong>Marketing Communications:</strong>&nbsp;If you wish to stop receiving our emails, <br />&nbsp;&nbsp;please unsubscribe from within the email itself.</p>
            <div className="flex justify-end mt-6">
              <button 
                  onClick={() => setShowModal(false)} 
                  className="mr-2 px-4 py-2 rounded-lg border"
              >
                  Cancel
              </button>
              <button 
                  onClick={handleDelete} 
                  className="px-4 py-2 rounded-lg bg-red-500 text-white"
              >
                  Confirm
              </button>
            </div>
          </div>
        </div>
      )}  
    </>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
});

const mapDispatch = {  
  deleteAccount,
  updateAccountDetails,
};

export default connect(mapState, mapDispatch)(AccountInfo);
