const StripeSuccess = () => {
  return (
    <div className="pt-24 flex flex-col items-center justify-center">
    <div className="max-w-lg p-6 bg-white rounded-lg">
      <div className="text-center">
        <span className="text-6xl">🎉</span>
        <h1 className="text-3xl font-bold mt-4 mb-4 text-gray-700">Thank you for your purchase!</h1>
        <p className="text-gray-600 text-xl">Your tokens will be allocated shortly. 🚀</p>
      </div>
    </div>
  </div>
  );
};

export default StripeSuccess;