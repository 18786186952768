import { connect } from "react-redux";
import { useEffect } from "react";
import {validateSession} from "../actions/user.actions";
import VideoListItem from '../components/VideoListItem';
import { EmptyScreen } from "../components/EmptyScreen";
import { fetchVideos } from '../actions/videos.actions';

const AssetLibrary = ({ 
  videos,
  fetchVideos
}) => {  

  useEffect(() => { 
    fetchVideos(100)   
  }, []);

  return (
    <div className="p-10">
      <h1 className="text-6xl font-black pt-10 text-[#170E08]">Asset Library</h1>
      {videos.length > 0 ? (
          <div className="grid grid-cols-4 gap-4 mt-10">
            {  videos.map(video => ( 
              <VideoListItem video={video} />         
            ))}
          </div>
        ) : (
          <div className='col-span-4 mt-20'>
              <EmptyScreen title="No assets uploaded yet" message="Press the button and start uploading assets" />
          </div>
        )}
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
  videos: state.video.videos, 
});

const mapDispatch = {  
  validateSession,  
  fetchVideos
};

export default connect(mapState, mapDispatch)(AssetLibrary);
