import { connect } from "react-redux";
import { useEffect } from "react";
import { EmptyScreen } from "../components/EmptyScreen";
import {validateSession} from "../actions/user.actions";
import { AnalyticsInfo } from "../components/AnalyticsInfo";
import VideoListItem from '../components/VideoListItem';
import { fetchVideos } from '../actions/videos.actions';

const Dashboard = ({
  currentUser,
  validateSession, 
  fetchVideos,
  videos
}) => {    

  useEffect(() => {
    validateSession();
    fetchVideos(4);
  }, []);

  return (
    <div className="p-10">
      <h1 className="text-6xl font-black pt-10 text-[#170E08]">Dashboard</h1>
      <div className="flex mt-14 mb-24">
        <AnalyticsInfo name="Videos" value={videos.length} />        
        <AnalyticsInfo name="Tokens" value={currentUser.tokens} />
      </div>
      <h2 className="text-2xl pb-3 font-black text-[#2D3139]">Your latest videos</h2>
        {videos.length > 0 ? (
          <div className="grid grid-cols-4 gap-4 mt-10">
            {  videos.map(video => ( 
              <VideoListItem video={video} />         
            ))}
          </div>
        ) : (
          <div className='col-span-4 mt-20'>
              <EmptyScreen title="No videos yet" message="Go create your first video!" />
          </div>
        )}
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
  videos: state.video.videos,  
});

const mapDispatch = {  
  validateSession,  
  fetchVideos
};

export default connect(mapState, mapDispatch)(Dashboard);
