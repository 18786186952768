import SleepyFace from '../assets/SleepyFace.png'

export function EmptyScreen({ title, message }) {
  return (
    <div
      className='lg:bg-input flex bg-[#F7F7F8] mt-2 h-full w-full items-center justify-center rounded-2xl lg:py-20'>
      <div className="flex flex-col items-center">
        <img className="mb-6 h-24 w-24" src={SleepyFace} alt="empty state" />
        <p className="mb-2 text-center text-xl font-bold">
          {title}
        </p>
        <p className="mb-6 text-center">
          {message}
        </p>        
      </div>
    </div>
  );
}
