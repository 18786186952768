import axios from 'axios';
const URL = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: URL
});

// Interceptor to attach the token for every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;