export const LayoutIcon = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.stroke || '#2D3139'}
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 3H3v7h18V3z" />
    <path d="M21 14h-5v7h5v-7z" />
    <path d="M12 14H3v7h9v-7z" />
  </svg>
);