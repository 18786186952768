import {
  SIGNIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGNUP_SUCCESS,  
} from "./actions/types";

const storeUser = (user) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};

export const userStorage = (store) => (next) => (action) => {
  if (action.type === SIGNIN_SUCCESS || action.type === SIGNUP_SUCCESS) {    
    if (action.payload) {
      if (action.payload == null) {
        // Load from localStorage
        storeUser(action.payload);
      } else {
        // Load user from API
        storeUser(action.payload);        
      }
    }
  }

  if (action.type === LOGOUT_SUCCESS) {
    localStorage.clear();
  }
  next(action);
};
