import { loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from '../api/checkout';
import { CHECKOUT_SESSION_BEGIN, CHECKOUT_SESSION_ERROR, CHECKOUT_SESSION_SUCCESS } from './types';

const publicKey = process.env.REACT_APP_PUBLIC_STRIPE_KEY
const stripePromise = loadStripe(publicKey);

export const initiateCheckout = () => async dispatch => {
    dispatch({ type: CHECKOUT_SESSION_BEGIN });

    try {        
        const response = await createCheckoutSession();   
        console.log("Session ID:", response.data.session_id);   

        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId: response.data.session_id });

        dispatch({ type: CHECKOUT_SESSION_SUCCESS });
    } catch (error) {
        console.error("Error in initiateCheckout:", error);
        if (error.response && error.response.data) {            
            console.error("Server response:", error.response.data);
        }
        dispatch({ type: CHECKOUT_SESSION_ERROR, payload: error.message });
    }
};
