import { connect } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PublicLayout = ({ currentUser }) => {
  return currentUser ? (
    <Navigate to="/dashboard" />
  ) : (
    <>
      <Outlet />
    </>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,
});

export default connect(mapState)(PublicLayout);