// SignIn.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../actions/user.actions';
import BackgroundImage from '../components/BackgroundImage';
import { Link } from 'react-router-dom';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);    

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(signin(email, password));
    };        

    return (
      <div className="flex h-screen">
        {/* Left Side: Image */}
        <div className="w-[60%]">
          <BackgroundImage />          
        </div>
        
        {/* Right Side: Form */}
        <div className="w-[40%] flex flex-col justify-center p-12 bg-white relative"> 

          <h1 className="text-7xl font-black mb-8 text-[#170E08] absolute top-20 left-1/2 transform -translate-x-1/2 mt-6">futurads</h1>
          <h2 className="text-3xl mb-1 font-bold">Login</h2>
          <p className='mb-5 font-extralight'>Don't have an account? <Link to='/signup' className='text-[#FF600D]' >Sign Up</Link></p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="username">Email</label>              
              <input
                type="email"
                placeholder="a.dent@bbc.radio"
                className="border rounded-lg p-2 w-full"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="password">Password</label>              
              <input
                type="password"
                placeholder="*****************"
                className="border rounded-lg p-2 w-full"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />   
            </div>

            {error && <p className="text-red-500">{error}</p>}
            <button
              type="submit"
              className="bg-[#FF600D] text-white rounded-full p-2 w-full mt-4 hover:bg-opacity-90"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Sign In'}
            </button>            
          </form>
        </div>      
      </div>                         
    );
};

export default SignIn;