import { connect } from "react-redux";

const Insights = ({
  currentUser,  
}) => {  

  return (
    <div className="p-10">
      <h1 className="text-6xl font-black pt-10 text-[#170E08]">Insights</h1>
      
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
});

const mapDispatch = {  
  
};

export default connect(mapState, mapDispatch)(Insights);
