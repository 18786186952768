import { connect } from "react-redux";
import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";

const Profile = () => {    
  useEffect(() => { 
    
  }, []);

  return (
    <div className="p-10">
      <h1 className="text-6xl font-black pt-10 text-[#170E08]">Account</h1>      
      <div>
        <div className="flex space-x-4 mt-10">
          <NavLink 
            to="/account" 
            end
            className={({ isActive }) => isActive 
                ? "px-4 py-2 rounded-full text-dark-orange bg-light-orange transition-colors"
                : "px-4 py-2 rounded-full text-gray-blue bg-light-gray transition-colors"}
            >
              Profile Info
            </NavLink>
            <NavLink 
                to="/account/password" 
                className={({ isActive }) => isActive 
                ? "px-4 py-2 rounded-full text-dark-orange bg-light-orange transition-colors"
                : "px-4 py-2 rounded-full text-gray-blue bg-light-gray transition-colors"}
            >
                Change Password
            </NavLink>
            <NavLink 
                to="/account/billing" 
                className={({ isActive }) => isActive 
                ? "px-4 py-2 rounded-full text-dark-orange bg-light-orange transition-colors"
                : "px-4 py-2 rounded-full text-gray-blue bg-light-gray transition-colors"}
            >
                Billing
            </NavLink>            
        </div>            
        <Outlet />
      </div>
    </div>    
);
};

const mapState = (state) => ({  
  currentUser: state.user.user,
});

const mapDispatch = {
  
}

export default connect(mapState, mapDispatch)(Profile);
