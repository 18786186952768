import { connect } from "react-redux";
import VideoUpload from "../components/VideoUpload";

const Dubbs = ({
  currentUser,  
}) => {  

  return (
    <div className="p-10">
      <h1 className="text-6xl font-black pt-10 text-[#170E08]">Dubb</h1>
      <VideoUpload />
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
});

const mapDispatch = {  
  
};

export default connect(mapState, mapDispatch)(Dubbs);
