import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import thunk from "redux-thunk";
import reducer from "./reducers";
import { Provider } from "react-redux";
import { userStorage } from "./middleware";
import { createStore, applyMiddleware, compose } from "redux";
import { loadLastUser } from "./actions/user.actions";

//import posthog from 'posthog-js';
//posthog.init(process.env.REACT_APP_POSTHOG_TOKEN, {api_host: 'https://app.posthog.com'});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk, userStorage)
);

const store = createStore(reducer, enhancer);
store.dispatch(loadLastUser());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
