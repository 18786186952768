import { combineReducers } from "redux";
import user from "./user";
import video from "./video";
import checkout from "./checkout";
import { LOGOUT_SUCCESS } from "../actions/types";

const combinedReducers = combineReducers({
  user: user,
  video: video,
  checkout: checkout,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;
