const StripeCancelled = () => {
  return (
    <div className="pt-24 flex flex-col items-center justify-center">
      <div className="max-w-lg p-6 bg-white rounded-lg">
        <div className="text-center">
          <span className="text-6xl">😕</span>
          <h1 className="text-3xl font-bold mt-4 mb-4 text-gray-700">Transaction Cancelled</h1>
          <p className="text-gray-600 text-xl">It seems you've decided not to proceed. If you have any concerns, 
            <a href="mailto:support@futurads.io" className="text-blue-500 hover:underline ml-1">please let us know</a>. 💌
          </p>
        </div>
      </div>
    </div>
  );
};

export default StripeCancelled;
