import React, { useRef } from 'react';
import { useState } from 'react';
import { connect, useSelector } from "react-redux";
import { uploadVideo } from '../actions/videos.actions';
import LanguageSelection from './LanguageSelection';

const MAX_FILE_SIZE_MB = 25; // 25 MB
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // convert MB to bytes

const VideoUpload = ({ uploadVideo, uploadedVideo }) => {    
    const { uploading, error, dubbedVideo } = useSelector(state => state.video);    
    const [isDragging, setIsDragging] = useState(false);
    const [videoTitle, setVideoTitle] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [videoDisplayed, setVideoDisplayed] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default to English
    const [selectedDubOption, setSelectedDubOption] = useState('dub');    

    const fileInputRef = useRef(null);

    const handleLanguageChange = (event) => {
      setSelectedLanguage(event.target.value);
    };

    const handleDubOptionChange = (event) => {
        setSelectedDubOption(event.target.value);
    };    

    const handleVideoRemoval = () => {
        setVideoTitle(null);
        setVideoDisplayed(false);
    };

    const handleDubbing = () => {    
        console.log(`Video ID: ${uploadedVideo.id}, Language: ${selectedLanguage}, Dub Option: ${selectedDubOption}`);
    };
  
    const handleFileChange = (event) => {
        const file = event.target.files[0];     
        if (file) {
          if (file.size > MAX_FILE_SIZE) {
            setUploadError(`File size should be less than ${MAX_FILE_SIZE_MB} MB`);
            return;
          }
          setVideoTitle(file.name);
          uploadVideo(file);
          setUploadError(null);
        }
    };

    const openFileDialog = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) uploadVideo(file);
        setIsDragging(false);
    };

    return (
      <>
        {dubbedVideo ? (
            <video className="w-full rounded-lg" controls>
                <source src={dubbedVideo.video_file} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        ) : uploadedVideo && videoDisplayed ? (
              <div className="flex flex-col items-center justify-center p-6 pt-10">
                  <div className="flex items-start">
                      <div className="video-container w-full max-h-md max-w-md mr-6">
                          <video className="w-full rounded-lg" controls>
                              <source src={uploadedVideo.video_file} type="video/mp4" />
                              Your browser does not support the video tag.
                          </video>
                      </div>
                      <div>
                          <div className="mb-10">
                              <h3 className="text-2xl font-semibold mb-2">{videoTitle || 'Video Title'}</h3>
                              <p className="text-gray-600">Uploaded on: {new Date().toLocaleDateString()}</p>                              
                          </div>
                          <div className="mb-10">
                              <label htmlFor="language" className="block mb-2">Language:</label>
                              <select id="language" onChange={handleLanguageChange} value={selectedLanguage} className="border p-2 rounded">
                                <LanguageSelection />
                              </select>
                          </div>
                          <div className="mb-10">
                              <label htmlFor="dubbingOption" className="block mb-2">Dubbing Option:</label>
                              <select id="dubbingOption" onChange={handleDubOptionChange} value={selectedDubOption} className="border p-2 rounded">
                                  <option value="dub">Dub</option>
                                  <option value="dubLipSync">Dub + Lip Sync</option>
                              </select>
                          </div>
                          <button className="px-4 mb-10 py-2 bg-[#FF600D] text-white rounded-lg" onClick={handleDubbing} disabled>
                              Send for Processing
                          </button>
                          <div>
                            <button
                                onClick={handleVideoRemoval}
                                className="mt-4 bg-gray-400 hover:bg-gray-500 text-white py-1 px-4 rounded transition duration-300 ease-in-out"
                            >
                                Remove Video
                            </button>
                          </div>
                      </div>
                  </div>
              </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-6 pt-36">
              <div className="w-full max-w-md bg-white p-6 rounded-lg">
                  <div className="text-center mb-4">
                      <span className="text-3xl">📹</span>
                      <h2 className="text-xl font-bold mt-2">Upload Your Video</h2>
                      {error && <p className="text-red-500 mt-2">{error}</p>}
                      {uploadError && <p className="text-red-500 mt-2">{uploadError}</p>}
                  </div>
                  <div 
                      onClick={openFileDialog}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      className={`flex flex-col items-center justify-center p-6 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 transition-colors duration-300 ${isDragging ? 'bg-[#FF600D]' : ''}`}
                  >
                      <input 
                          type="file" 
                          accept="video/mp4,video/mpeg,video/webm,audio/m4a" 
                          className="hidden" 
                          ref={fileInputRef}
                          onChange={handleFileChange}
                      />
                      <span className="text-lg font-thin">Drag & drop or click to upload</span>
                      {uploading && <p className="text-gray-600 mt-2">Uploading...</p>}                      
                  </div>                  
                  <div className='text-center'>
                    <p className="text-xs mt-4 text-gray-500 font-light">
                      File uploads are limited to {MAX_FILE_SIZE_MB} MB. <br />Supported file types: mp4, mpeg, m4a, webm.
                    </p>
                  </div>
              </div>
            </div>
          )}      
      </>
  );
}

const mapState = (state) => ({
  currentUser: state.user.user, 
  uploadedVideo: state.video.uploadedVideo 
});

const mapDispatch = {  
  uploadVideo,  
};

export default connect(mapState, mapDispatch)(VideoUpload);
