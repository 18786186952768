import { connect } from "react-redux";
import BuyTokensButton from "../components/BuyTokenButton";

const Billing = ({currentUser}) => {  

  return (
    <div className="pt-16">
      <h2 className="text-2xl font-semibold mb-4 text-[#170E08]">Tokens Available</h2>      
      <p className="text-xl font-light"><span className="text-dark-orange">{currentUser.tokens}</span> tokens</p>
      <h2 className="text-2xl mt-16 font-semibold mb-4 text-[#170E08]">Purchase More Tokens</h2>      
      <BuyTokensButton />
    </div>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,  
});

const mapDispatch = {  
  
};

export default connect(mapState, mapDispatch)(Billing);
