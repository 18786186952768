// SignIn.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../actions/user.actions';
import BackgroundImage from '../components/BackgroundImage';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');

    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    const navigate = useNavigate();

    const handleSubmit = e => {
      e.preventDefault();
      dispatch(signup(email, password, firstName, lastName, company))        
    };

    useEffect(() => {
      if (isAuthenticated) {  
        navigate('/dashboard');
      }
    }, [isAuthenticated, navigate]);

    return (
      <div className="flex h-screen">
        {/* Left Side: Image */}
        <div className="w-[60%]">
          <BackgroundImage />          
        </div>

        {/* Right Side: Form */}
        <div className="w-[40%] flex flex-col justify-center p-12 bg-white relative"> 
          <h1 className="text-7xl font-black mb-8 text-[#170E08] absolute top-20 left-1/2 transform -translate-x-1/2 mt-6">futurads</h1>
          <h2 className="text-3xl mb-1 font-bold">Sign Up</h2>
          <p className='mb-5 font-extralight'>Already have an account? <Link to='/signin' className='text-[#FF600D]' >Sign In</Link></p>
          <form onSubmit={handleSubmit}>              
            <div className="flex mb-4 space-x-4"> {/* Wrap them in a flex container */}
              <div className="flex-1"> {/* Take half of the available space */}
                <label className="block text-sm font-medium mb-2" htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  placeholder="Arthur"
                  className="border rounded-lg p-2 w-full"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="flex-1"> {/* Take the other half */}
                <label className="block text-sm font-medium mb-2" htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  placeholder="Dent"
                  className="border rounded-lg p-2 w-full"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="username">Company</label>              
              <input
                type="text"
                placeholder="BBC Radio"
                className="border rounded-lg p-2 w-full"
                value={company}
                onChange={e => setCompany(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="username">Email</label>              
              <input
                type="email"
                placeholder="a.dent@bbc.radio"
                className="border rounded-lg p-2 w-full"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </div>              
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="password">Password</label>              
              <input
                type="password"
                placeholder="Password"
                className="border rounded-lg p-2 w-full"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />   
            </div>            
            {error && <p className="text-red-500">{error}</p>}
            <button
              type="submit"
              className="bg-[#FF600D] text-white rounded-full p-2 w-full mt-4 hover:bg-opacity-90"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Sign Up'}
            </button>            
          </form>
        </div>
      </div>      
    );
};

export default SignUp;