import { produce } from 'immer';
import { 
    UPLOAD_VIDEO_BEGIN, 
    UPLOAD_VIDEO_SUCCESS, 
    UPLOAD_VIDEO_ERROR,
    SAVE_UPLOADED_VIDEO,
    FETCH_VIDEOS_BEGIN,
    FETCH_VIDEOS_SUCCESS,
    FETCH_VIDEOS_ERROR,
    DELETE_VIDEO_BEGIN,
    DELETE_VIDEO_SUCCESS,
    DELETE_VIDEO_ERROR
} from '../actions/types';

const initialState = {
    uploading: false,
    uploadSuccess: false,
    error: null,
    videos: [],
    loading: false,
};

const video = produce((draft, action) => {
    switch (action.type) {
        case FETCH_VIDEOS_BEGIN:
            draft.loading = true;
            draft.error = null;
            break;
        case DELETE_VIDEO_BEGIN:
            draft.loading = true;
            draft.error = null;
            break;
        case DELETE_VIDEO_SUCCESS:
            const index = draft.videos.findIndex(video => video.id === action.payload);
            if (index !== -1) draft.videos.splice(index, 1);
                draft.loading = false;
            break;
        case DELETE_VIDEO_ERROR:
            draft.loading = false;
            draft.error = action.payload;
            break;
        case UPLOAD_VIDEO_BEGIN:
            draft.uploading = true;
            draft.error = null;
            draft.uploadSuccess = false;
            break;
        case UPLOAD_VIDEO_SUCCESS:
            draft.uploading = false;
            draft.uploadSuccess = true;
            break;
        case FETCH_VIDEOS_SUCCESS:        
            draft.loading = false;
            draft.videos = action.payload.results;
            break;
        case SAVE_UPLOADED_VIDEO:
            draft.uploadedVideo = action.payload;
            break;
        case UPLOAD_VIDEO_ERROR:
            draft.uploading = false;
            draft.error = action.payload;
            break;
        case FETCH_VIDEOS_ERROR:
            draft.loading = false;
            draft.error = action.payload;
            draft.videos = [];
            break;
        default:
            break;
    }
}, initialState);

export default video;
