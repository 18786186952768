import { useState } from "react";

const EditableInput = ({ label, initialValue, onSave }) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
    setIsEditing(true); 
  };

  return (
    <div className="relative mt-4">
      <label className="block text-sm font-medium mb-2">{label}</label>
      <input 
        type="text" 
        value={value} 
        onChange={handleChange}
        className="border rounded-lg p-2 w-full"
      />
      {isEditing && 
        <button 
          onClick={() => {
            onSave(value);
            setIsEditing(false); 
          }}
          className="absolute top-1/2 transform -translate-y-[7%] right-2 bg-[#2D3139] text-white px-2 py-1 rounded"
        >
          Save
        </button>
      }
    </div>
  );
};

export default EditableInput;