import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import { logout } from "../actions/user.actions";
import { Outlet, Navigate } from "react-router-dom";

const AppLayout = ({ logout, currentUser }) => {    
  return currentUser ? (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-4">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/signIn" />
  );
};

const mapState = (state) => ({
  currentUser: state.user.user
});

const mapDispatch = {
  logout,  
};

export default connect(mapState, mapDispatch)(AppLayout);
