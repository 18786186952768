import { produce } from 'immer';
import { CHECKOUT_SESSION_BEGIN, CHECKOUT_SESSION_ERROR, CHECKOUT_SESSION_SUCCESS } from '../actions/types';

const initialState = {
  loading: false,
  error: null
};

const checkout = produce((draft, action) => {
    switch (action.type) {
      case CHECKOUT_SESSION_BEGIN:
          draft.loading = true;
          draft.error = null;
          break;
      case CHECKOUT_SESSION_SUCCESS:
          draft.loading = false;
          break;
      case CHECKOUT_SESSION_ERROR:
          draft.loading = false;
          draft.error = action.payload;
          break;
      default:
          break;
    }
}, initialState);

export default checkout;