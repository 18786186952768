import {
  loginUser,
  createUser,  
  getCurrentUser,  
  deleteUser,
  updateUserDetails,
  putPassword
} from "../api/users";

import {
  SIGNIN_BEGIN,
  SIGNIN_ERROR,
  SIGNUP_BEGIN,
  SIGNUP_ERROR,
  SIGNIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  DELETE_ACCOUNT_BEGIN,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_BEGIN,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR
} from "./types";

export const signin = (username, password) => {
  return (dispatch) => {
    dispatch({ type: SIGNIN_BEGIN });
    loginUser(username, password)
      .then((response) => {             
        localStorage.setItem('accessToken', response.data.access_token)
        getCurrentUser()
          .then((response) => {            
            dispatch({
              type: SIGNIN_SUCCESS,
              payload: response.data
            });
          })
          .catch((error) => {
            dispatch({ type: SIGNIN_ERROR, error: error.response.data.message });
          });        
      })
      .catch((error) => {        
        dispatch({ type: SIGNIN_ERROR, error: error.response.data.message });
      });
  };
};

export const signup = (email, password, first_name, last_name, company) => {
  return async (dispatch) => {
    dispatch({ type: SIGNUP_BEGIN });
    try {
      const data = {
        email,        
        password,
        first_name,
        last_name,
        company        
      };
      const response = await createUser(data);
      if (response.data.access_token) {        
        localStorage.setItem('accessToken', response.data.access_token);        
        getCurrentUser()
          .then((resp) => {                        
            dispatch({
              type: SIGNUP_SUCCESS,
              payload: resp.data
            });
          })
          .catch((error) => {
            dispatch({ type: SIGNUP_ERROR, error: error.response.data.message });
          });                     
      } else {
        // Handle the error - Token not found in the response
        dispatch({ type: SIGNUP_ERROR, error: "Signup succeeded but no token was provided" });
      }              
    }
    catch (error) {
      dispatch({ type: SIGNUP_ERROR, error: error.response.data.error });
    }
  };
};

export const loadLastUser = () => {
  return (dispatch) => {
    const json = localStorage.getItem("currentUser");    
    if (json && json !== "undefined") {
      const user = JSON.parse(json);      
      dispatch({
        type: SIGNIN_SUCCESS,
        payload: user,
      });
    } else {
      dispatch(logout());
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
};

export const validateSession = () => {
  return async (dispatch) => {
    try {      
      const getUser = await getCurrentUser();      
      getUser.status !== 200 && dispatch(logout());
    } catch {
      dispatch(logout());
    }
  };
};

export const updateAccountDetails = (data) => async (dispatch) => {
  dispatch({type: UPDATE_ACCOUNT_BEGIN})
  try {
    const response = await updateUserDetails(data);
    dispatch({ type: UPDATE_ACCOUNT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_ACCOUNT_ERROR, error: error.response.data.error });
  }
};

export const deleteAccount = () => async (dispatch) => {
  dispatch({type: DELETE_ACCOUNT_BEGIN})
  try {
    deleteUser().then((resp) => {
      dispatch({ type: DELETE_ACCOUNT_SUCCESS });
      dispatch({type: LOGOUT_SUCCESS})
    })    
  } catch (error) {
    dispatch({ type: DELETE_ACCOUNT_ERROR, error: error.response.data.error });
  }
};

export const updatePassword = (data) => async (dispatch) => {
  dispatch({type: CHANGE_PASSWORD_BEGIN})
  try {
    const response = await putPassword(data);
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
    dispatch({ type: LOGOUT_SUCCESS})
  } catch (error) {
    dispatch({ type: CHANGE_PASSWORD_ERROR, error: error.response.data.error });
  }
};